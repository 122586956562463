import type { Theme } from '@emotion/react';
import { buildTheme } from 'common-ui';
import merge from 'lodash/merge';
import type { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types';
import { buildThemeMixinsUSOsw, buildThemeWidgetStylesUSOsw, buildThemeVarsUSOsw } from 'dw-us-osw/theme';
const createTheme: ThemeCreateFunction = () =>
  buildTheme(
    (vars: Pick<Theme, CommonThemeVars>) => merge({}, buildThemeVarsUSOsw(vars)),
    (vars: Omit<Theme, 'mixins' | 'widgets'>) => buildThemeMixinsUSOsw(vars),
    (vars: Omit<Theme, 'widgets'>) => buildThemeWidgetStylesUSOsw(vars),
  );

export default createTheme;
